<template>
  <div class="component horizontal-padding">
    <h1>Mentorship</h1>

    <spinner
      v-if="status === 'loading'"
      preset="large"
    />

    <template v-else-if="status === 'error'">
      <alert variant="danger">
        An error occurred loading your mentorship data. Please check your
        connection and try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadMentorshipData"
      >
        Try Again
      </button>
    </template>

    <template v-else-if="status === 'loaded'">
      <template v-if="mentor.username !== undefined">
        <h2>Your Mentor</h2>

        <div class="mentor users">
          <router-link
            :to="{ name: 'user_profile', params: { username: mentor.username } }"
            class="user"
          >
            <profile-image :user="mentor" />
            <div>
              {{ mentor.first_name }} {{ mentor.last_name }}
            </div>
          </router-link>
        </div>
      </template>

      <h2>
        Your Mentees

        <template v-if="mentees.count">
          ({{ mentees.count }})
        </template>
      </h2>

      <template v-if="mentees.count">
        <div
          v-if="mentees.count > 1"
          class="mentee-filters-content"
        >
          <transition-slide mode="out-in">
            <div
              v-if="menteeFilters.showing === false"
              class="text-center"
            >
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                @click="menteeFilters.showing = true"
              >
                Show Filters
              </button>
            </div>

            <div v-else>
              <h3>Filters</h3>

              <div class="mentee-filters">
                <fieldset>
                  <legend>Subscription status</legend>

                  <div
                    v-for="option of menteeFilters.subscriptionStatuses.options"
                    :key="`subscriptionStatus${option.value}`"
                    class="form-check"
                  >
                    <input
                      :id="`subscription-status-${option.value}`"
                      v-model="menteeFilters.subscriptionStatuses.selected"
                      type="checkbox"
                      :value="option.value"
                      class="form-check-input"
                      @change="loadMentees"
                    >
                    <label
                      :for="`subscription-status-${option.value}`"
                      class="form-check-label"
                    >
                      {{ option.text }}
                    </label>
                  </div>
                </fieldset>

                <fieldset>
                  <legend>IPG status</legend>

                  <div
                    v-for="option of menteeFilters.ipgStatus.options"
                    :key="`ipgStatus${option.value}`"
                    class="form-check"
                  >
                    <input
                      :id="`ipg-status-${option.value}`"
                      v-model="menteeFilters.ipgStatus.selected"
                      type="checkbox"
                      :value="option.value"
                      class="form-check-input"
                      @change="loadMentees"
                    >
                    <label
                      :for="`ipg-status-${option.value}`"
                      class="form-check-label"
                    >
                      {{ option.text }}
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </transition-slide>
        </div>

        <spinner
          v-if="menteesStatus === 'loading'"
          preset="large"
        />

        <template v-else-if="menteesStatus === 'error'">
          <alert variant="danger">
            An error occurred loading your mentees. Please check your
            connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadMentees"
          >
            Try Again
          </button>
        </template>

        <template v-else-if="menteesStatus === 'loaded'">
          <template v-if="mentees.results.length">
            <div class="users">
              <router-link
                v-for="mentee of mentees.results"
                :key="`mentee${mentee.username}`"
                :to="{ name: 'user_profile', params: { username: mentee.username } }"
                class="user"
              >
                <profile-image :user="mentee" />
                <div>
                  <div>{{ mentee.first_name }} {{ mentee.last_name }}</div>

                  <div>Joined on {{ dateDisplay(mentee.date_joined) }}</div>

                  <div>
                    Subscription status:

                    <span
                      :class="{
                        'green-text': mentee.subscription_status === 'active',
                        'red-text': mentee.subscription_status === 'inactive',
                      }"
                    >
                      <template v-if="mentee.subscription_status === 'not_subscribed'">
                        Not subscribed
                      </template>

                      <template v-else>
                        {{ capitalizeWords(mentee.subscription_status) }}
                      </template>
                    </span>
                  </div>

                  <div>
                    IPG Status:

                    <span
                      :class="{
                        'green-text': mentee.ipg_status === 'active',
                        'red-text': mentee.ipg_status.startsWith('inactive'),
                      }"
                    >
                      <template v-if="mentee.ipg_status === 'active'">
                        Active
                      </template>

                      <template v-else-if="mentee.ipg_status === 'inactive_not_removed'">
                        Inactive (pending removal from genealogy tree)
                      </template>

                      <template v-else-if="mentee.ipg_status === 'inactive_and_removed'">
                        Inactive (removed from genealogy tree)
                      </template>
                    </span>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="load-more-content">
              <button
                v-show="moreMenteesStatus === 'idle' && mentees.next"
                ref="loadMoreMenteesButton"
                type="button"
                class="btn btn-outline-primary load-more-mentees-button"
                @click="loadMoreMentees"
              >
                Load More Mentees
              </button>

              <spinner
                v-if="moreMenteesStatus === 'loading'"
                preset="large"
              />

              <template v-else-if="moreMenteesStatus === 'error'">
                <alert variant="danger">
                  An error occurred while trying to load more mentees. Please check
                  your connection and try again.
                </alert>

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="loadMoreMentees"
                >
                  Try Again
                </button>
              </template>
            </div>
          </template>

          <p
            v-else
            class="text-center"
          >
            You have no mentees that match your criteria.
          </p>
        </template>
      </template>

      <div
        v-else
        class="text-center"
      >
        <p>You don't have any mentees yet.</p>

        <p>
          <router-link
            :to="{ name: 'ipg_invitations' }"
            class="btn btn-outline-primary btn-xs"
          >
            Invite a friend
          </router-link>
        </p>
      </div>

      <h2>
        Your Customers

        <template v-if="customers.count">
          ({{ customers.count }})
        </template>
      </h2>

      <template v-if="customers.count">
        <div
          v-if="customers.count > 1"
          class="customer-filters-content"
        >
          <transition-slide mode="out-in">
            <div
              v-if="customerFilters.showing === false"
              class="text-center"
            >
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                @click="customerFilters.showing = true"
              >
                Show Filters
              </button>
            </div>

            <div v-else>
              <h3>Filters</h3>

              <div class="customer-filters">
                <fieldset>
                  <legend>Subscription status</legend>

                  <div
                    v-for="option of customerFilters.subscriptionStatuses.options"
                    :key="`subscriptionStatus${option.value}`"
                    class="form-check"
                  >
                    <input
                      :id="`subscription-status-${option.value}`"
                      v-model="customerFilters.subscriptionStatuses.selected"
                      type="checkbox"
                      :value="option.value"
                      class="form-check-input"
                      @change="loadCustomers"
                    >
                    <label
                      :for="`subscription-status-${option.value}`"
                      class="form-check-label"
                    >
                      {{ option.text }}
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </transition-slide>
        </div>

        <spinner
          v-if="customersStatus === 'loading'"
          preset="large"
        />

        <template v-else-if="customersStatus === 'error'">
          <alert variant="danger">
            An error occurred loading your customers. Please check your
            connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadCustomers"
          >
            Try Again
          </button>
        </template>

        <template v-else-if="customersStatus === 'loaded'">
          <template v-if="customers.results.length">
            <div class="users">
              <router-link
                v-for="customer of customers.results"
                :key="`customer${customer.username}`"
                :to="{ name: 'user_profile', params: { username: customer.username } }"
                class="user"
              >
                <profile-image :user="customer" />
                <div>
                  <div>{{ customer.first_name }} {{ customer.last_name }}</div>

                  <div>Joined on {{ dateDisplay(customer.date_joined) }}</div>

                  <div>
                    Subscription status:

                    <span
                      :class="{
                        'green-text': customer.subscription_status === 'active',
                        'red-text': customer.subscription_status === 'inactive',
                      }"
                    >
                      {{ capitalizeWords(customer.subscription_status) }}
                    </span>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="load-more-content">
              <button
                v-show="moreCustomersStatus === 'idle' && customers.next"
                ref="loadMoreCustomersButton"
                type="button"
                class="btn btn-outline-primary load-more-customers-button"
                @click="loadMoreCustomers"
              >
                Load More Customers
              </button>

              <spinner
                v-if="moreCustomersStatus === 'loading'"
                preset="large"
              />

              <template v-else-if="moreCustomersStatus === 'error'">
                <alert variant="danger">
                  An error occurred while trying to load more customers. Please
                  check your connection and try again.
                </alert>

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="loadMoreCustomers"
                >
                  Try Again
                </button>
              </template>
            </div>
          </template>

          <p
            v-else
            class="text-center"
          >
            You have no customers that match your criteria.
          </p>
        </template>
      </template>

      <div
        v-else
        class="text-center"
      >
        <p>You don't have any customers yet.</p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { ApiError } from '@virgodev/bazaar/functions/api';
import { defineComponent } from 'vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { UserInterface, UserWithStatusesAndDateJoinedInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    TransitionSlide,
    ProfileImage,
  },
  data: () => ({
    abortControllers: {
      mentees: null as null | AbortController,
      moreMentees: null as null | AbortController,
      customers: null as null | AbortController,
      moreCustomers: null as null | AbortController,
    },
    status: 'loading' as 'loading' | 'loaded' | 'error',
    mentor: {} as UserInterface,
    mentees: {} as {
      count: number,
      next: string | null,
      previous: string | null,
      results: Array<UserWithStatusesAndDateJoinedInterface>,
    },
    menteesStatus: 'loading' as 'loading' | 'loaded' | 'error',
    moreMenteesStatus: 'idle' as 'idle' | 'loading' | 'error',
    menteesObserver: null as null | IntersectionObserver,
    menteeFilters: {
      showing: false,
      subscriptionStatuses: {
        options: [
          { value: 'not_subscribed', text: 'Not subscribed' },
          { value: 'active', text: 'Active' },
          { value: 'inactive', text: 'Inactive' },
        ],
        selected: [] as Array<string>,
      },
      ipgStatus: {
        options: [
          { value: 'active', text: 'Active' },
          { value: 'inactive_not_removed', text: 'Inactive (pending removal from genealogy tree)' },
          { value: 'inactive_and_removed', text: 'Inactive (removed from genealogy tree)' },
        ],
        selected: [] as Array<string>,
      },
    },
    customers: {} as {
      count: number,
      next: string | null,
      previous: string | null,
      results: Array<UserWithStatusesAndDateJoinedInterface>,
    },
    customersStatus: 'loading' as 'loading' | 'loaded' | 'error',
    moreCustomersStatus: 'idle' as 'idle' | 'loading' | 'error',
    customersObserver: null as null | IntersectionObserver,
    customerFilters: {
      showing: false,
      subscriptionStatuses: {
        options: [
          { value: 'active', text: 'Active' },
          { value: 'inactive', text: 'Inactive' },
        ],
        selected: [] as Array<string>,
      },
    },
  }),
  created() {
    this.loadMentorshipData();
  },
  beforeUnmount() {
    if (this.menteesObserver) {
      this.menteesObserver.disconnect();
    }

    if (this.customersObserver) {
      this.customersObserver.disconnect();
    }
  },
  methods: {
    async loadMentorshipData() {
      this.status = 'loading';

      const responseData = await this.api({ url: 'business-portal/mentorship/' });

      if (responseData.status === 200) {
        if (responseData.body.mentor !== undefined) {
          this.mentor = responseData.body.mentor;
        }

        this.mentees = responseData.body.mentees;
        this.menteesStatus = 'loaded';
        this.status = 'loaded';

        if (this.mentees.next) {
          this.$nextTick(() => {
            this.menteesObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreMentees();
                }
              });
            });

            this.menteesObserver.observe(this.$refs.loadMoreMenteesButton as HTMLButtonElement);
          });
        }

        this.customers = responseData.body.customers;
        this.customersStatus = 'loaded';
        this.status = 'loaded';

        if (this.customers.next) {
          this.$nextTick(() => {
            this.customersObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreCustomers();
                }
              });
            });

            this.customersObserver.observe(this.$refs.loadMoreCustomersButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMentees() {
      if (this.abortControllers.mentees) {
        this.abortControllers.mentees.abort();
      }

      if (this.abortControllers.moreMentees) {
        this.abortControllers.moreMentees.abort();
        this.moreMenteesStatus = 'idle';
      }

      this.menteesStatus = 'loading';

      const params = {
        list_type: 'mentees',
      } as Record<string, string | Array<string>>;

      if (this.menteeFilters.subscriptionStatuses.selected.length) {
        params.subscription_status = this.menteeFilters.subscriptionStatuses.selected;
      }

      if (this.menteeFilters.ipgStatus.selected.length) {
        params.ipg_status = this.menteeFilters.ipgStatus.selected;
      }

      this.abortControllers.mentees = new AbortController();

      const responseData = await this.api({
        url: 'business-portal/mentorship/',
        params,
        options: {
          signal: this.abortControllers.mentees.signal,
        },
      });

      if (responseData.status === 200) {
        this.mentees.results = responseData.body.results;
        this.mentees.next = responseData.body.next;
        this.menteesStatus = 'loaded';

        if (this.mentees.next) {
          this.$nextTick(() => {
            this.menteesObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreMentees();
                }
              });
            });

            this.menteesObserver.observe(this.$refs.loadMoreMenteesButton as HTMLButtonElement);
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.menteesStatus = 'error';
      }
    },
    async loadMoreMentees() {
      this.moreMenteesStatus = 'loading';

      this.abortControllers.moreMentees = new AbortController();

      const responseData = await this.api({
        url: this.mentees.next as string,
        options: {
          signal: this.abortControllers.moreMentees.signal,
        },
      });

      if (responseData.status === 200) {
        this.mentees.next = responseData.body.next;
        this.mentees.previous = responseData.body.previous;
        this.mentees.results = this.mentees.results.concat(responseData.body.results);
        this.moreMenteesStatus = 'idle';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.moreMenteesStatus = 'error';
      }
    },
    async loadCustomers() {
      if (this.abortControllers.customers) {
        this.abortControllers.customers.abort();
      }

      if (this.abortControllers.moreCustomers) {
        this.abortControllers.moreCustomers.abort();
        this.moreCustomersStatus = 'idle';
      }

      this.customersStatus = 'loading';

      const params = {
        list_type: 'customers',
      } as Record<string, string | Array<string>>;

      if (this.customerFilters.subscriptionStatuses.selected.length) {
        params.subscription_status = this.customerFilters.subscriptionStatuses.selected;
      }

      this.abortControllers.customers = new AbortController();

      const responseData = await this.api({
        url: 'business-portal/mentorship/',
        params,
        options: {
          signal: this.abortControllers.customers.signal,
        },
      });

      if (responseData.status === 200) {
        this.customers.results = responseData.body.results;
        this.customers.next = responseData.body.next;
        this.customersStatus = 'loaded';

        if (this.customers.next) {
          this.$nextTick(() => {
            this.customersObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreCustomers();
                }
              });
            });

            this.customersObserver.observe(this.$refs.loadMoreCustomersButton as HTMLButtonElement);
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.customersStatus = 'error';
      }
    },
    async loadMoreCustomers() {
      this.moreCustomersStatus = 'loading';

      this.abortControllers.moreCustomers = new AbortController();

      const responseData = await this.api({
        url: this.customers.next as string,
        options: {
          signal: this.abortControllers.moreCustomers.signal,
        },
      });

      if (responseData.status === 200) {
        this.customers.next = responseData.body.next;
        this.customers.previous = responseData.body.previous;
        this.customers.results = this.customers.results.concat(responseData.body.results);
        this.moreCustomersStatus = 'idle';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.moreCustomersStatus = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    margin: 0 auto;
    max-width: 500px;
  }

  .mentor,
  .mentee-filters-content,
  .customer-filters-content {
    margin-bottom: 1rem;
  }

  .mentee-filters {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }

  .users {
    border-radius: 6px;
    overflow: hidden;
  }

  .user {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    padding: 10px 16px;
    color: #fff;
    background-color: var(--gray-darker);
    transition: background-color 0.15s ease-in-out;
    word-wrap: break-word;

    @supports #{'\selector(*:focus-visible)'}  {
      &:focus {
        outline: 0;
      }

      &:focus-visible {
        background-color: var(--gray-dark);
      }
    }

    &:hover {
      background-color: var(--gray-dark);
      text-decoration: none;
    }

    + .user {
      border-top: 1px solid var(--gray-darkest);
    }
  }

  .load-more-content {
    margin-top: 1rem;
  }

  .load-more-mentees-button,
  .load-more-customers-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
