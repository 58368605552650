
import { ApiError } from '@virgodev/bazaar/functions/api';
import { defineComponent } from 'vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { UserInterface, UserWithStatusesAndDateJoinedInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    TransitionSlide,
    ProfileImage,
  },
  data: () => ({
    abortControllers: {
      mentees: null as null | AbortController,
      moreMentees: null as null | AbortController,
      customers: null as null | AbortController,
      moreCustomers: null as null | AbortController,
    },
    status: 'loading' as 'loading' | 'loaded' | 'error',
    mentor: {} as UserInterface,
    mentees: {} as {
      count: number,
      next: string | null,
      previous: string | null,
      results: Array<UserWithStatusesAndDateJoinedInterface>,
    },
    menteesStatus: 'loading' as 'loading' | 'loaded' | 'error',
    moreMenteesStatus: 'idle' as 'idle' | 'loading' | 'error',
    menteesObserver: null as null | IntersectionObserver,
    menteeFilters: {
      showing: false,
      subscriptionStatuses: {
        options: [
          { value: 'not_subscribed', text: 'Not subscribed' },
          { value: 'active', text: 'Active' },
          { value: 'inactive', text: 'Inactive' },
        ],
        selected: [] as Array<string>,
      },
      ipgStatus: {
        options: [
          { value: 'active', text: 'Active' },
          { value: 'inactive_not_removed', text: 'Inactive (pending removal from genealogy tree)' },
          { value: 'inactive_and_removed', text: 'Inactive (removed from genealogy tree)' },
        ],
        selected: [] as Array<string>,
      },
    },
    customers: {} as {
      count: number,
      next: string | null,
      previous: string | null,
      results: Array<UserWithStatusesAndDateJoinedInterface>,
    },
    customersStatus: 'loading' as 'loading' | 'loaded' | 'error',
    moreCustomersStatus: 'idle' as 'idle' | 'loading' | 'error',
    customersObserver: null as null | IntersectionObserver,
    customerFilters: {
      showing: false,
      subscriptionStatuses: {
        options: [
          { value: 'active', text: 'Active' },
          { value: 'inactive', text: 'Inactive' },
        ],
        selected: [] as Array<string>,
      },
    },
  }),
  created() {
    this.loadMentorshipData();
  },
  beforeUnmount() {
    if (this.menteesObserver) {
      this.menteesObserver.disconnect();
    }

    if (this.customersObserver) {
      this.customersObserver.disconnect();
    }
  },
  methods: {
    async loadMentorshipData() {
      this.status = 'loading';

      const responseData = await this.api({ url: 'business-portal/mentorship/' });

      if (responseData.status === 200) {
        if (responseData.body.mentor !== undefined) {
          this.mentor = responseData.body.mentor;
        }

        this.mentees = responseData.body.mentees;
        this.menteesStatus = 'loaded';
        this.status = 'loaded';

        if (this.mentees.next) {
          this.$nextTick(() => {
            this.menteesObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreMentees();
                }
              });
            });

            this.menteesObserver.observe(this.$refs.loadMoreMenteesButton as HTMLButtonElement);
          });
        }

        this.customers = responseData.body.customers;
        this.customersStatus = 'loaded';
        this.status = 'loaded';

        if (this.customers.next) {
          this.$nextTick(() => {
            this.customersObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreCustomers();
                }
              });
            });

            this.customersObserver.observe(this.$refs.loadMoreCustomersButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMentees() {
      if (this.abortControllers.mentees) {
        this.abortControllers.mentees.abort();
      }

      if (this.abortControllers.moreMentees) {
        this.abortControllers.moreMentees.abort();
        this.moreMenteesStatus = 'idle';
      }

      this.menteesStatus = 'loading';

      const params = {
        list_type: 'mentees',
      } as Record<string, string | Array<string>>;

      if (this.menteeFilters.subscriptionStatuses.selected.length) {
        params.subscription_status = this.menteeFilters.subscriptionStatuses.selected;
      }

      if (this.menteeFilters.ipgStatus.selected.length) {
        params.ipg_status = this.menteeFilters.ipgStatus.selected;
      }

      this.abortControllers.mentees = new AbortController();

      const responseData = await this.api({
        url: 'business-portal/mentorship/',
        params,
        options: {
          signal: this.abortControllers.mentees.signal,
        },
      });

      if (responseData.status === 200) {
        this.mentees.results = responseData.body.results;
        this.mentees.next = responseData.body.next;
        this.menteesStatus = 'loaded';

        if (this.mentees.next) {
          this.$nextTick(() => {
            this.menteesObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreMentees();
                }
              });
            });

            this.menteesObserver.observe(this.$refs.loadMoreMenteesButton as HTMLButtonElement);
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.menteesStatus = 'error';
      }
    },
    async loadMoreMentees() {
      this.moreMenteesStatus = 'loading';

      this.abortControllers.moreMentees = new AbortController();

      const responseData = await this.api({
        url: this.mentees.next as string,
        options: {
          signal: this.abortControllers.moreMentees.signal,
        },
      });

      if (responseData.status === 200) {
        this.mentees.next = responseData.body.next;
        this.mentees.previous = responseData.body.previous;
        this.mentees.results = this.mentees.results.concat(responseData.body.results);
        this.moreMenteesStatus = 'idle';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.moreMenteesStatus = 'error';
      }
    },
    async loadCustomers() {
      if (this.abortControllers.customers) {
        this.abortControllers.customers.abort();
      }

      if (this.abortControllers.moreCustomers) {
        this.abortControllers.moreCustomers.abort();
        this.moreCustomersStatus = 'idle';
      }

      this.customersStatus = 'loading';

      const params = {
        list_type: 'customers',
      } as Record<string, string | Array<string>>;

      if (this.customerFilters.subscriptionStatuses.selected.length) {
        params.subscription_status = this.customerFilters.subscriptionStatuses.selected;
      }

      this.abortControllers.customers = new AbortController();

      const responseData = await this.api({
        url: 'business-portal/mentorship/',
        params,
        options: {
          signal: this.abortControllers.customers.signal,
        },
      });

      if (responseData.status === 200) {
        this.customers.results = responseData.body.results;
        this.customers.next = responseData.body.next;
        this.customersStatus = 'loaded';

        if (this.customers.next) {
          this.$nextTick(() => {
            this.customersObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreCustomers();
                }
              });
            });

            this.customersObserver.observe(this.$refs.loadMoreCustomersButton as HTMLButtonElement);
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.customersStatus = 'error';
      }
    },
    async loadMoreCustomers() {
      this.moreCustomersStatus = 'loading';

      this.abortControllers.moreCustomers = new AbortController();

      const responseData = await this.api({
        url: this.customers.next as string,
        options: {
          signal: this.abortControllers.moreCustomers.signal,
        },
      });

      if (responseData.status === 200) {
        this.customers.next = responseData.body.next;
        this.customers.previous = responseData.body.previous;
        this.customers.results = this.customers.results.concat(responseData.body.results);
        this.moreCustomersStatus = 'idle';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.moreCustomersStatus = 'error';
      }
    },
  },
});
